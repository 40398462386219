import dayjs from "dayjs";
import * as Config from "@/config/constants";
import * as Redmine from "@/config/redmine-constants";
import { Issue, TimeEntry } from "./types/interfaces";
import { getDPPe, getEntrySpeed } from "@/views/tree/tree-helpers";


function getHour(hours) {
    return Math.floor(hours);
}
function getMinute(hours) {
    return Math.round((hours % 1) * 60);
}
export function timeEntryToDayjs(timeEntry: Partial<TimeEntry>, startTime = '09:00') {
    const storedDate = timeEntry.spent_on + ' ' + (timeEntry.start_time || startTime);
    return dayjs.tz(storedDate, timeEntry.tz);
}
export function populateTimes(events) {
    let currentDayjs;
    events.sort((a, b) => {
        const astart = a.timeEntry.spent_on + (a.timeEntry.start_time || '');
        const bstart = b.timeEntry.spent_on + (b.timeEntry.start_time || '');
        if (astart > bstart) return 1;
        if (astart < bstart) return -1;
        return 0;
    }).forEach(event => {
        const timeEntry: TimeEntry = event.timeEntry;
        let startTime;
        if (currentDayjs) {
            const lastDate = currentDayjs.format(Config.DATE_FORMAT);
            if (lastDate === timeEntry.spent_on) {
                startTime = currentDayjs.format(Config.TIME_FORMAT);
            }
        }
        currentDayjs = timeEntryToDayjs(timeEntry, startTime);
        event.start = currentDayjs.toISOString();
        currentDayjs = currentDayjs
            .add(getHour(timeEntry.hours), 'hour')
            .add(getMinute(timeEntry.hours), 'minute')
            ;
        event.end = currentDayjs.toISOString();
    });
}


function getHours(hour, minute) {
    if (!hour && !minute) return 0;
    return hour + minute / 60;
}
export function getHoursFromTime(time) {
    if (!time) return 0;
    const times = time.split(':');
    return getHours(parseInt(times[0]), parseInt(times[1]));
}
export function getTimeFromHours(hours) {
    return getHour(hours).toString().padStart(2, '0') + ':' + getMinute(hours).toString().padStart(2, '0');
}


function getCustomerFromProject(projectName: string) {
    const matches = projectName.match(/\[(.*)\]/);
    if (matches.length < 2) return projectName;
    return matches[1];
}


function createDiv(value, tag = 'div') {
    let div = document.createElement(tag);
    if (value !== null) div.innerText = value;
    return div;
}
function createDivWithClasses(value, classes) {
    let div = createDiv(value);
    div.classList.add(...classes);
    return div;
}
export function renderEvent({ event, isMirror }) {
    const domNodes = [];
    const timeEntry: TimeEntry = event.extendedProps.timeEntry;
    if (!timeEntry) {
        domNodes.push(createDiv(event.title));
        if (event.display === 'background') return { domNodes };
        // Start time
        domNodes.push(createDivWithClasses(dayjs(event.start).format(Config.TIME_FORMAT), ['opacity7', 'd-timegrid-show', 'event_resize_start']));
        return { domNodes };
    }
    // Month view start time
    if (timeEntry.start_time) {
        domNodes.push(createDivWithClasses(timeEntry.start_time, ['opacity7', 'd-daygrid-show', 'mr-1']));
    }
    // Client
    const projectName = timeEntry?.project?.name;
    let project;
    if (projectName) project = createDiv(getCustomerFromProject(projectName), 'b');
    else project = createDiv('Closed', 'i');
    project.classList.add('mr-1');
    domNodes.push(project);

    // Standard view
    if (!isMirror) {
        // Issue
        domNodes.push(createDiv(timeEntry.issue_name, (timeEntry.hours > .5 ? 'div' : 'span')));
        // Start time
        if (timeEntry.start_time) {
            domNodes.push(createDivWithClasses(timeEntry.start_time, ['opacity7', 'd-timegrid-show']));
        }
        // Done ✓
        if (timeEntry.future_time === Redmine.CUSTOM_FIELD_FUTURE_TIME_NO) {
            domNodes.push(createDivWithClasses(null, ['real_item']));
        }
        return { domNodes };
    }

    // Mirror view (move or resize)
    let duration = event.end - event.start;
    if (duration > 2600 * 1000) {
        // Start time
        domNodes.push(createDivWithClasses(dayjs(event.start).format(Config.TIME_FORMAT), ['opacity7', 'd-timegrid-show', 'event_resize_start']));
    }
    // End time (+ duration)
    let start = (Math.round(duration / 36000) / 100) + 'h - ' + dayjs(event.end).format(Config.TIME_FORMAT);
    domNodes.push(createDivWithClasses(start, ['opacity7', 'd-timegrid-show', 'event_resize_end']));

    return { domNodes };
}


function getEarlyLateEntryColor(entry: TimeEntry, issue: Issue) {
    if (!Redmine.DPPEABLE_VERSION_TYPE.includes(issue.version_type)
        || entry.future_time === Redmine.CUSTOM_FIELD_FUTURE_TIME_YES) {
        return Config.PRIMARY_COLOR;
    }
    if (!issue.estimated_hours && !issue.sold_hours) return Config.PRIMARY_COLOR;
    const speed = getEntrySpeed(entry, issue);
    const minHueCap = -30;
    const maxHueCap = 150;
    const h = (Math.min(Math.max((speed) * maxHueCap, minHueCap), 150)).toString(10);
    return `hsl(${h}, 80%, 30%)`;
}
function getEarlyLateIssueColor(timeEntry: TimeEntry, issue: Issue) {
    if (!Redmine.DPPEABLE_VERSION_TYPE.includes(issue.version_type)
        || timeEntry.future_time === Redmine.CUSTOM_FIELD_FUTURE_TIME_YES) {
        return Config.PRIMARY_COLOR;
    }
    const dppe = getDPPe(issue);
    const dppeCap = 100;
    let dppeAbs = Math.abs(dppe);
    dppeAbs = dppeAbs > dppeCap ? dppeCap : dppeAbs;
    const dppeFactor = dppeAbs / dppeCap / 2;
    const h = ((dppeFactor * (dppe > 0 ? -1 : 1) + .5) * 120).toString(10);
    return `hsl(${h}, 80%, 30%)`;
}
export function getEventColor(timeEntry: TimeEntry, issue: Issue, colorTheme) {
    if (timeEntry.version_type === Redmine.CUSTOM_FIELD_VERSION_TYPE_LEAVE) {
        return '#2e813d';
    }
    if (!colorTheme) return Config.PRIMARY_COLOR;
    if (colorTheme.id === Config.COLOR_THEME_EARLY_LATE_ISSUE) return getEarlyLateIssueColor(timeEntry, issue);
    if (colorTheme.id === Config.COLOR_THEME_EARLY_LATE_ENTRY) return getEarlyLateEntryColor(timeEntry, issue);
    return '#000';
}


export function sameDay(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
}


const PROJECT_FILTER_KEY = 'resource_manager_calendar_project_filters_';
export function saveProjectFilters(projectId, filters) {
    if (!filters) return localStorage.removeItem(PROJECT_FILTER_KEY + projectId);
    localStorage.setItem(PROJECT_FILTER_KEY + projectId, JSON.stringify(filters));
}
export function loadProjectFilters(projectId) {
    const projectFilters = localStorage.getItem(PROJECT_FILTER_KEY + projectId);
    if (!projectFilters) return null;
    return JSON.parse(projectFilters);
}


function moveableNextEvents(currentEvent, events, skipConfirm = false) {
    if (!events.find(event => event.start.getTime() === currentEvent.end.getTime())) return;
    const nexts = events.filter(event => {
        return sameDay(event.start, currentEvent.start)
            && event.start.getTime() > currentEvent.start.getTime()
            && event.extendedProps.timeEntry.id !== currentEvent.extendedProps.timeEntry.id
            ;
    });
    if (nexts.filter(event => event.extendedProps.timeEntry.locked).length) return;
    if (!nexts.length) return;
    if (!skipConfirm && !window.confirm('Do you want to move the following ' + nexts.length + ' time entrie(s) alongside ?')) return;
    return nexts;
}


/**
 * Gets the list of moveable event directly following the currently modified one
 * @param events Events of the day
 * @param newEvent Event after modification
 * @param oldEvent Event before modification
 * @param skipConfirm Allows to skip the browser confirm dialog (mostly for tests)
 * @returns 
 */
export function getCrudUpdatesForDelta(events, newEvent, oldEvent, skipConfirm = false) {
    const moveableEvents = moveableNextEvents(oldEvent, events, skipConfirm);
    if (!moveableEvents || !moveableEvents.length) return [];
    const millisecondsDelta = (newEvent.start.getTime() - oldEvent.start.getTime()) || (newEvent.end.getTime() - oldEvent.end.getTime())
    return moveableEvents.map(event => {
        const date = dayjs(event.start).add(millisecondsDelta, 'ms');
        // const date = dayjs.tz(event.start, Config.REDMINE_TIMEZONE).add(millisecondsDelta, 'ms');
        return {
            id: event.extendedProps.timeEntry.id,
            date: date.format(Config.DATE_FORMAT),
            startTime: date.format(Config.TIME_FORMAT),
        };
    });
}
